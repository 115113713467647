import Vue from "vue";

import Vuex from "vuex";
Vue.use(Vuex);

import pageTransitions from "./pageTransitions";
import scrollableElements from "./scrollableElements";

export default new Vuex.Store(
  {
    modules: {pageTransitions, scrollableElements}
  }
);
